import React from 'react'
import Layout from '../components/Layout'
import config from '../../config'
import FourOhFour from '../../../common/components/Error'
import { IntersectionProvider } from '@common/hooks/useIntersection'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../components/Layout/styles'
import Container from '@common/components/Container'
import theme from '../themes'
import Footer from '../components/Footer'
import { LocaleProvider, useLogin } from '@common/hooks'
import Navigation from '../components/Navigation'
import { parseQueryString } from '../../../common/utils'
import LoginBlockGatsbyCDAContainer from '../containers/LoginBlockContainer/LoginBlockGatsbyCDAContainer'
import TitleAndMetaTags from '@common/components/TitleAndMetaTags'

const ErrorPage = ({ data }) => {
  let locale = 'en-US'
  let production = true
  let metaTitle = 'Xbox Series X | S'
  let metaDescription =
    'Games load significantly faster with the custom SSD in the Xbox Series X. Video depicting significantly reduced load times on the Xbox Series X.'
  let homeSlug = '/'
  let preview = false

  return (
    <ThemeProvider theme={theme}>
      <LocaleProvider locale={locale}>
        <TitleAndMetaTags
          config={config}
          lang={locale}
          metaTitle={metaTitle}
          metaDescription={metaDescription}
        />
        <GlobalStyle />
        {production && (
          <Navigation preview={preview} homeSlug={homeSlug} loginPage />
        )}
        <div style={{ height: '61px' }} />
        <Container>
          <FourOhFour configEmail={config.authorDefaultEmail} />
        </Container>
        {production && <Footer preview={preview} homeSlug={homeSlug} />}
      </LocaleProvider>
    </ThemeProvider>
  )
}

export default ErrorPage
