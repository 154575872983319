import React from 'react'
import { FourOhFourContainer, ErrorContainer, H1, H2 } from './style'
import PropTypes from 'prop-types'

const FourOhFour = () => {
  return (
    <FourOhFourContainer
      bg={`dark.bg`}
      m="0 auto"
      color="dark.text"
      minHeight="50vh"
      fontFamily="headings"
    >
      <ErrorContainer
        width="100%"
        m="0 auto"
        p="10vh 0px 5vh"
        color="dark.text"
        fontSize={24}
        alt="A video game still showing a man running toward an explosion"
        src="https://compass-ssl.xbox.com/assets/b3/3e/b33ef6aa-897c-42d9-bf7d-2be410306cd3.jpg?n=404-500_Page-Hero-1084_Crackdown-3_1920x720.jpg"
      />
      <H1 fontSize={44} p="0px 10vw">
        Wrong way, Agent.
      </H1>
      <br />
      <H2 p="0px 10vw">Page not found.</H2>
    </FourOhFourContainer>
  )
}

FourOhFour.propTypes = {
  configEmail: PropTypes.string,
}

export default FourOhFour
