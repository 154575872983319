import React from 'react'
import { ThemeProvider } from 'styled-components'
import TitleAndMetaTags from '../../../../common/components/TitleAndMetaTags'
import { GlobalStyle } from './styles'
import Container from '../../../../common/components/Container'
import Navigation from '../Navigation'
import Footer from '../Footer'
import theme from '../../themes'
import { NProgessProvider, LocaleProvider } from '../../../../common/hooks'
import useIntersection from '@common/hooks/useIntersection'
import { useLogin } from '@common/hooks'
import Redirect from '@common/components/Redirect'
import { buildThresholdArr } from '@common/utils'

const Layout = ({ children, config, preview, location, locale, ...rest }) => {
  useIntersection({
    threshold: buildThresholdArr(0.05),
    preview,
  })

  const localeSlug = locale === 'en-US' ? '' : `/${locale}`
  const homeSlug = preview
    ? `${localeSlug}/preview/?t=xbox&id=4c4o2bt9cRrhdKVqGZlnlO`
    : `${localeSlug || '/'}`

  const { auth } = useLogin()
  const { isAuthenticated } = auth
  if (!isAuthenticated) return <Redirect to={localeSlug} />

  return (
    <ThemeProvider theme={theme}>
      <LocaleProvider locale={locale}>
        <NProgessProvider>
          <GlobalStyle />
          <TitleAndMetaTags config={config} lang={locale} {...rest} />
          <Navigation preview={preview} homeSlug={homeSlug} />
          <div style={{ height: '61px' }} />
          <Container>{children}</Container>
          <Footer preview={preview} homeSlug={homeSlug} />
        </NProgessProvider>
      </LocaleProvider>
    </ThemeProvider>
  )
}

export default Layout
